import React from 'react';
import './appview.module.scss'; // Ensure this is imported if additional custom styles are needed
import deliveryImage from '../../images/appview.png'; // Ensure the path to your image is correct

const DeliveryStats = () => {
    return (
        <div className="delivery-stats-container bg-white rounded-lg">
            <div className='max-w-[1200px] flex flex-col md:flex-row items-center justify-between p-5 m-auto'>
               
                <div className="text-content flex flex-col justify-center text-center md:text-left md:pl-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">Our Delivery Services</h2>
                    <p className="text-gray-600 leading-relaxed">
                    Our delivery services continue to reach new heights, reflecting our ongoing growth and unwavering commitment to providing fast, reliable, and top-quality service. Your trust drives us to deliver excellence with every order, and we’re excited to keep exceeding your expectations!
                    </p>
                </div>
                <img src={deliveryImage} alt="Total Delivery" className="rounded-lg shadow-lg mb-6 md:mb-0 w-full md:w-1/2" />
            </div>
        </div>
    );
};

export default DeliveryStats;
