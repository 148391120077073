import React from 'react';
import { useTranslation } from 'react-i18next';
import './targetusers.module.scss';  // Ensure this path is correct
import providerImage from '../../images/provider-image.jpg';  // Path to the service provider image
import customerImage from '../../images/customer.jpg';  // Path to the customer image

interface UserCardProps {
    image: string;
    title: string;
    description: string;
    buttonText?: string;
    buttonLink?: string;
}

const TargetUsers = () => {
    const { t } = useTranslation()
    return (
        <div className="target-users-section bg-gray-100 py-12">
            <div className="container mx-auto px-6">
                <h2 className="text-3xl font-bold text-center mb-10">{t('targetDCustomers.title')}</h2>
                <div className="flex justify-around items-center flex-wrap">
                    <UserCard
                        image="https://www.wexinc.com/wp-content/uploads/2023/11/The-trucking-business-keeps-America-going-4-maintenance-strategies-for-your-heavy-duty-trucks-WEX.png.webp"
                        title={t('targetDCustomers.targetusers.customer.title')}
                        description={t('targetDCustomers.targetusers.customer.desc')}
                        buttonText="Join as Provider"
                        buttonLink="#providerSignUp"
                    />
                    <UserCard
                        image={customerImage}
                        title={t('targetDCustomers.targetusers.serviceProvider.title')}
                        description={t('targetDCustomers.targetusers.serviceProvider.desc')}
                    // buttonText="Get Started"
                    // buttonLink="#customerSignUp"
                    />
                </div>
            </div>
        </div>
    );
};

const UserCard: React.FC<UserCardProps> = ({ image, title, description, buttonText, buttonLink }) => (
    <div className="user-card bg-white rounded-lg shadow-md p-6 m-4 flex-1 max-w-md">
        <img src={image} alt={title} className="w-full h-[300px] bg-cover mb-4 mx-auto rounded-t-lg" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="mb-4">{description}</p>
        {/* {buttonText === 'Join as Provider' && <a href={buttonLink} className="bg-white border border-indigo-600 text-black px-4 py-2 rounded">{buttonText}</a>} */}

    </div>
);

export default TargetUsers;
