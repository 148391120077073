import React from 'react';
import { useTranslation } from 'react-i18next';
import './features.module.scss';
import trackingImage from '../../images/location.png';
import userFriendly from '../../images/login.png';
import quality from '../../images/quality.png';

interface FeatureProps {
    imgSrc: string;
    title: string;
    description: string;
    reverse?: boolean; // optional prop
}

const FeaturesSection = () => {
    const { t } = useTranslation()
    return (
        <div className="features-section bg-white py-12">
            <div className="container mx-auto">
                <Feature
                    imgSrc={trackingImage}
                    title={t('features.tracking.title')}
                    description={t('features.tracking.desc')}
                    reverse={false}
                />
                <Feature
                    imgSrc={userFriendly}
                    title={t('features.userFriendly.title')}
                    description={t('features.userFriendly.desc')}
                    reverse={true}
                />
                <Feature
                    imgSrc={quality}
                    title={t('features.Quality.title')}
                    description={t('features.Quality.desc')}
                    reverse={false}
                />
            </div>
        </div>
    );
};

const Feature: React.FC<FeatureProps> =  ({ imgSrc, title, description, reverse }) => (
    <div className={`feature flex items-center ${reverse ? 'flex-row-reverse' : 'flex-row'} my-10`}>
        <div className="feature-text w-1/2 p-6">
            <h3 className="text-2xl font-bold mb-4">{title}</h3>
            <p>{description}</p>
        </div>
        <div className="feature-image w-1/2 flex justify-center bg-gray-100 lg:h-[400px] m-auto">
            <img src={imgSrc} alt={title} className="w-48 h-48 m-auto" />
        </div>
    </div>
);

export default FeaturesSection;
