import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './navbar.module.scss';
import Logo from '../../images/estarehlogo.jpeg'

const Navbar: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [scrolled, setScrolled] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`${styles.navbar} ${scrolled ? styles.scrolled : ''}`}>
            <div className="container mx-auto flex justify-between items-center py-4 px-4">
                <div className="text-2xl font-bold">
                    <img src={Logo} width="80" alt="estareh logo" />
                </div>
                <div className="flex space-x-4">
                    <Link to="/" className={`${styles.text_white} ${scrolled ? styles.scrolled : ''}  px-4`}>{t('navbar.about')}</Link>
                    <Link to="/contact" className={`${styles.text_white} ${scrolled ? styles.scrolled : ''}  px-4`}>{t('navbar.contact')}</Link>
                    <Link to="/download" className={`${styles.text_white} ${scrolled ? styles.scrolled : ''}  px-4`}>{t('navbar.download')}</Link>
                    {i18n.language === 'ar' ? <button onClick={() => i18n.changeLanguage('en')} className={`${styles.text_white} ${scrolled ? styles.scrolled : ''} px-4`}>EN</button>
                        : <button onClick={() => i18n.changeLanguage('ar')} className={`${styles.text_white} ${scrolled ? styles.scrolled : ''} px-4`}>AR</button>}

                </div>
            </div>
        </nav>
    );
};

export default Navbar;
