import React from 'react';
import './footer.module.scss';  // Ensure this path is correct

const Footer = () => {
    return (
        <footer className="footer bg-gray-100 text-white text-center py-4">
            <div className="container mx-auto px-4 text-black">
                © {new Date().getFullYear()} Estreeh. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
