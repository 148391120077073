import React from 'react';
import './serviceitem.module.scss';

interface ServiceItemProps {
    icon: string;
    title: string;
    description: string;
}


const ServiceItem: React.FC<ServiceItemProps> = ({ icon, title, description }) => {
    return (
        <div className="service-item bg-white shadow-lg rounded-lg p-6 m-4">
            <img src={icon} alt={title} className="h-24 w-24 mb-4" />
            <h3 className="text-xl font-bold mb-2">{title}</h3>
            <p>{description}</p>
        </div>
    );
};

export default ServiceItem;
