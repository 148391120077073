import React from 'react';
import ServiceItem from '../serviceitem/serviceitem';
import { useTranslation } from 'react-i18next';
import deliveryIcon from '../../images/delivery-truck.png';
import heavyEquipmentIcon from '../../images/excavator.png';
import servicesIcon from '../../images/transport.png';
import styles from './services.module.scss';

const ServicesSection = () => {
    const { t } = useTranslation()
    return (
        <section className={`${styles.servicesSection} bg-gray-100 py-12`}>
            <div className={`${styles.container} container mx-auto px-4`}>
                <h2 className="text-3xl font-bold text-center mb-8">{t('Services.title')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <ServiceItem
                        icon={deliveryIcon}
                        title={t('Services.service.delivery.title')}
                        description={t('Services.service.delivery.desc')}
                    />
                    <ServiceItem
                        icon={heavyEquipmentIcon}
                        title={t('Services.service.heavyequipments.title')}
                        description={t('Services.service.heavyequipments.desc')}
                    />
                    <ServiceItem
                        icon={servicesIcon}
                        title={t('Services.service.services.title')}
                        description={t('Services.service.services.desc')}
                    />
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
