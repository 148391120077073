import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import i18n from './locales/i18n'; // assuming your i18n configuration file is located in the 'locales' directory
import Navbar from './component/navbar/navbar';
import Hero from './component/hero/hero';
import FeaturesSection from './component/features/features';
import ServicesSection from './component/services/services';
import TargetUsers from './component/targetusers/targetusers'
import CTASection from './component/CTA/cta';
import Footer from './component/footer/footer';
import DeliveryStats from './component/appview/appview';



const App: React.FC = () => {
  const [currentLang, setCurrentLang] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      setCurrentLang(lng);
      document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
    };

    i18n.on('languageChanged', handleLanguageChange);

    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
    <Router>
      <Navbar />
      <Hero />
      <ServicesSection />
      <DeliveryStats />
      <TargetUsers />
      <FeaturesSection />
      <CTASection />
      <Footer />

      {/* Define routes and other components here */}
      {/* <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/download" element={<DownloadPage />} />
      </Routes> */}
    </Router>
  );
};

// const HomePage = () => <div>Home Page Content</div>;
// const ContactPage = () => <div>Contact Page Content</div>;
// const DownloadPage = () => <div>Download Page Content</div>;

export default App;
