import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './hero.module.scss';


const HeroSection = () => {

    const { t } = useTranslation()
    const [animationPlayed, setAnimationPlayed] = useState(false);

    useEffect(() => {
        // Check if the animation has already been played
        const hasPlayed = localStorage.getItem('animationPlayed');
        if (!hasPlayed) {
            setAnimationPlayed(true);
            localStorage.setItem('animationPlayed', 'true');
        }
    }, []);


    return (
        <div className={styles.heroSection}>
            <div className={styles.heroOverlay}>
                <div className={`typewriter ${animationPlayed ? '' : 'no-animation'}`}>
                    <h1 className={styles.heroTitle}>{t('heroSection.title')}</h1>
                </div>

            </div>
        </div>
    );
};

export default HeroSection;
