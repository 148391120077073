import React from 'react';
import { useTranslation } from 'react-i18next';
import './cta.module.scss';  // Ensure this path is correct
import iosLogo from '../../images/hero-appstore.png';  // Path to the iOS logo
import androidLogo from '../../images/hero-playstore.png';  // Path to the Android logo

const CTASection = () => {
    const { t } = useTranslation()
    return (
        <div className="cta-section bg-blue-900 py-12 text-white text-center">
            <div className="container mx-auto px-6">
                <h2 className="text-3xl font-bold mb-6">{t('CTA.title')}</h2>
                <p className="mb-8">{t('CTA.desc')}</p>
                <div className="flex justify-center space-x-4">
                    <a href="https://apps.apple.com/" className="download-button flex items-center  text-black px-4 py-2 rounded hover:bg-gray-200 transition duration-300">
                        <img src={iosLogo} alt="iOS" className="w-68 h-24 mr-4" />
                        {/* <span>Download on the App Store</span> */}
                    </a>
                    <a href="https://play.google.com/" className="download-button flex items-center  text-black px-4 py-2 rounded hover:bg-gray-200 transition duration-300">
                        <img src={androidLogo} alt="Android" className="w-68 h-24 mr-4" />
                        {/* <span>Get it on Google Play</span> */}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CTASection;
